import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    adminSettings: null,
};

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setAdminSettings: (state, action) => {
            state.adminSettings = action.payload;
        },
    },
});

export const { setAdminSettings } = adminSlice.actions;
export default adminSlice.reducer;
