import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userCred: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,

    setUserCred: (state, action) => {
      state.userCred = action.payload;
    },

  },
});

export const { reset, setUserCred } = authSlice.actions;
export default authSlice.reducer;
