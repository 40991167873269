import React from 'react'
import { Spinner } from 'react-bootstrap'

const FullPageLoader = () => {
    return (
        <div className='d-flex align-items-center justify-content-center w-100 h-100'>
            <Spinner variant='primary' size='md' />
        </div>
    )
}

export default FullPageLoader