import React, { lazy, Profiler, Suspense } from "react";
import ReactDOM from "react-dom/client";

import persistStore from "redux-persist/es/persistStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ToastContainer } from "react-toastify";
import FullPageLoader from "./Components/Loader/FullPageLoader";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./index.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import "react-circular-progressbar/dist/styles.css";
import "swiper/css";
import "swiper/css/navigation"; // Import navigation styles
import "./Styles/styles.scss";
import "swiper/css/pagination";
import "quill/dist/quill.snow.css";
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css'; 

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const persistor = persistStore(store);

const onRender = () => {};

root.render(
  <Suspense fallback={<FullPageLoader />}>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <PersistGate loading={<FullPageLoader />} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Profiler id="App" onRender={onRender}>
              <App />
            </Profiler>
          </QueryClientProvider>
        </PersistGate>
        <ToastContainer />
      </I18nextProvider>
    </Provider>
  </Suspense>
);
