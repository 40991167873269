import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organisationDetails: null,
};

export const organisationSlice = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    setOrganisation: (state, action) => {
      state.organisationDetails = action?.payload ?? null;
    },

    resetOrganisation: () => initialState,
  },
});

export const { setOrganisation, resetOrganisation } = organisationSlice.actions;
export default organisationSlice.reducer;
