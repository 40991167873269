import { createSlice } from '@reduxjs/toolkit';
import { THEME_TYPE_CONST } from '../../Constants/constants';

const initialState = {
  theme: THEME_TYPE_CONST.DEFAULT,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action?.payload ?? null;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
