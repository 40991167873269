export const themes = {
  default: {
    "--headerInner": " #ffffff",
    "--sidebar": " #275cc6",
    "--blueWhite": " #275cc6",
    "--blackWhite": " #ffffff",
    "--blackGrey": " #f6f9ff",
    "--black": " #10254f",
    "--middleBlack": " #475569",
    "--greyblack": " #E3EAF8",
    "--border": "#E3EAF8",
    "--bodyBg": " #f6f9ff",
    "--inputBg": " #ffffff",
    "--inputBorder": " #E3EAF8",
    "--btnBorder": "#E3EAF8",
    "--btnSecondary": "#F6F9FF",
    "--whiteBlackBox": "#ffffff",
    "--shadowBorder": " transparent",
    "--light-grey-black": " #E2E8F0",
    "--light-grey-black-2": " #F6F9FF",
    "--dropdownBg": " #ffffff",
    "--trStriped": " #FCFDFF",
    "--editor": " #F8FAFC",
    "--tab-2-border": " #B9CBED",

    // common hexa color code
    "--common-white": "#ffffff",
    "--common-danger": " #f88080",
    "--common-success": " #56be6c",
    "--common-info": " #59b0de",
    "--common-warning": " #f89d46",
    "--common-purple": " #5b3cce",
    "--common-blue": " #275cc6",
    "--common-grey": " #94A3B8",
    "--common-light-grey": "#CBD5E1",
    "--common-shadow": " #0b2d790f",

    // common rgba color code
    "--common-rgba-white": "255, 255, 255",
    "--common-rgba-danger": " 248, 128, 128",
    "--common-rgba-success": " 86, 190, 108",
    "--common-rgba-info": " 89, 176, 222",
    "--common-rgba-warning": " 248, 157, 70",
    "--common-rgba-purple": " 91, 60, 206",
    "--common-rgba-blue": " 39, 92, 198",
    "--common-rgba-grey": " 148, 163, 184",
  },
  black: {
    "--headerInner": " #081123",
    "--sidebar": " #151D2E",
    "--blueWhite": " #ffffff",
    "--blackWhite": " #081123",
    "--blackGrey": " #081123",
    "--black": " #F6F9FF",
    "--middleBlack": "#D0D2D5",
    "--greyblack": " #212939",
    "--border": "#4A505E",
    "--bodyBg": " #081123",
    // "--inputBg":" #151D2E",
    "--inputBg": " #212939",
    "--inputBorder": " transparent",
    "--btnBorder": "transparent",
    // "--btnSecondary":"#151D2E",
    "--btnSecondary": "#212939",
    "--whiteBlackBox": "#151D2E",
    "--shadowBorder": " #2c3342",
    "--light-grey-black": " #202839",
    "--light-grey-black-2": " #202839",
    // "--dropdownBg":"#151D2E",
    "--dropdownBg": "rgba(255, 255, 255, 0.05)",
    "--trStriped": "#182030",
    "--editor": " #2a3346",
    "--tab-2-border": " #B9CBED",

    // common hexa color code
    "--common-white": "#ffffff",
    "--common-danger": " #f88080",
    "--common-success": " #56be6c",
    "--common-info": " #59b0de",
    "--common-warning": " #f89d46",
    "--common-purple": " #5b3cce",
    "--common-blue": " #275cc6",
    "--common-grey": " #94A3B8",
    "--common-light-grey": " #CBD5E1",
    "--common-shadow": " #0b2d790f",

    // common rgba color code
    "--common-rgba-white": "255, 255, 255",
    "--common-rgba-danger": " 248, 128, 128",
    "--common-rgba-success": " 86, 190, 108",
    "--common-rgba-info": " 89, 176, 222",
    "--common-rgba-warning": " 248, 157, 70",
    "--common-rgba-purple": " 91, 60, 206",
    "--common-rgba-blue": " 39, 92, 198",
    "--common-rgba-grey": " 148, 163, 184",
    "--common-rgba-shadow": " 11, 45, 121, 0.059",
  },
};

export const THEME_TYPE_CONST = {
  DEFAULT: "default",
  BLACK: "black",
};

export const PAGINATION_ITEM_COUNT_OPTIONS = [
  {
    id: 1,
    name: 10,
    value: 10,
  },
  {
    id: 2,
    name: 20,
    value: 20,
  },
  {
    id: 3,
    name: 50,
    value: 50,
  },
  {
    id: 4,
    name: 100,
    value: 100,
  },
  {
    id: 5,
    name: 200,
    value: 200,
  },
  {
    id: 6,
    name: 500,
    value: 500,
  },
];

export const TIMEZONE_LIST = [
  { id: 1, value: "GMT", name: "account_settings.general.time_zone.GMT" },
  { id: 2, value: "UTC", name: "account_settings.general.time_zone.UTC" },
  { id: 3, value: "CET", name: "account_settings.general.time_zone.CET" },
  { id: 33, value: "WAT", name: "account_settings.general.time_zone.WAT" },
  { id: 4, value: "EET", name: "account_settings.general.time_zone.EET" },
  { id: 5, value: "ART", name: "account_settings.general.time_zone.ART" },
  { id: 6, value: "EAT", name: "account_settings.general.time_zone.EAT" },
  { id: 7, value: "MET", name: "account_settings.general.time_zone.MET" },
  { id: 8, value: "NET", name: "account_settings.general.time_zone.NET" },
  { id: 9, value: "PLT", name: "account_settings.general.time_zone.PLT" },
  { id: 10, value: "IST", name: "account_settings.general.time_zone.IST" },
  { id: 11, value: "BST", name: "account_settings.general.time_zone.BST" },
  { id: 12, value: "VST", name: "account_settings.general.time_zone.VST" },
  { id: 13, value: "CTT", name: "account_settings.general.time_zone.CTT" },
  { id: 14, value: "JST", name: "account_settings.general.time_zone.JST" },
  { id: 15, value: "ACT", name: "account_settings.general.time_zone.ACT" },
  { id: 16, value: "AET", name: "account_settings.general.time_zone.AET" },
  { id: 17, value: "SST", name: "account_settings.general.time_zone.SST" },
  { id: 18, value: "NST", name: "account_settings.general.time_zone.NST" },
  { id: 19, value: "MIT", name: "account_settings.general.time_zone.MIT" },
  { id: 20, value: "HST", name: "account_settings.general.time_zone.HST" },
  { id: 21, value: "AST", name: "account_settings.general.time_zone.AST" },
  { id: 22, value: "PST", name: "account_settings.general.time_zone.PST" },
  { id: 23, value: "PNT", name: "account_settings.general.time_zone.PNT" },
  { id: 24, value: "MST", name: "account_settings.general.time_zone.MST" },
  { id: 25, value: "CST", name: "account_settings.general.time_zone.CST" },
  { id: 26, value: "EST", name: "account_settings.general.time_zone.EST" },
  { id: 27, value: "IET", name: "account_settings.general.time_zone.IET" },
  {
    id: 28,
    value: "PRT",
    name: "account_settings.general.time_zone.PRT",
  },
  { id: 29, value: "CNT", name: "account_settings.general.time_zone.CNT" },
  { id: 30, value: "AGT", name: "account_settings.general.time_zone.AGT" },
  { id: 31, value: "BET", name: "account_settings.general.time_zone.BET" },
  { id: 32, value: "CAT", name: "account_settings.general.time_zone.CAT" },
];

export const getTimezoneList = (t) => {
  return TIMEZONE_LIST.map((timezone) => ({
    ...timezone,
    name: t(timezone.name),
  }));
};

// export const LANGUAGE_LIST = [
//   { id: 1, name: "English", value: "en" },
//   { id: 2, name: "Spanish", value: "es" },
//   // { id: 3, name: "French", value: "fr" },
//   // { id: 4, name: "German", value: "de" },
//   // { id: 5, name: "Chinese", value: "zh" },
// ];
export const LANGUAGE_LIST = [
  { id: 1, name: "English", value: "en" },
  { id: 2, name: "Spanish", value: "es" },
  { id: 3, name: "French", value: "fr" },
  // { id: 3, name: "French", value: "fr" },
  // { id: 4, name: "German", value: "de" },
  // { id: 5, name: "Chinese", value: "zh" },
];

// export const LANGUAGE_LIST = [
//   { code: "en", label: "English" },
//   { code: "es", label: "Spanish" },
// ];

export const LOGOUT_MINUTES_LIST = [
  {
    id: 1,
    name: "5 min",
    value: 5,
  },
  {
    id: 1,
    name: "10 min",
    value: 10,
  },
  {
    id: 1,
    name: "15 min",
    value: 15,
  },
  {
    id: 1,
    name: "20 min",
    value: 20,
  },
  {
    id: 1,
    name: "25 min",
    value: 25,
  },
  {
    id: 1,
    name: "30 min",
    value: 30,
  },
];

export const HOME_PAGE_TABS = [
  {
    id: 1,
    name: "dashboard.tabs.dashboard",
    path: "dashboard",
  },
  {
    id: 2,
    name: "dashboard.tabs.records",
    path: "records",
  },
  {
    id: 3,
    name: "dashboard.tabs.cv",
    path: "cv",
  },
];
export const FILETYPE = "logo" || "cover_picture" || "profile";
export const PROFILE = new Set(["logo", "profile", "orgLogo"]);
export const ORG_BANNER = new Set(["banner", "banner_image"]);
export const PROFILE_COVER = new Set(["cover", "cover_picture"]);
export const FILESIZE_FOR_LOGO_PROFILE = 2 * 1024 * 1024;
export const FILESIZE_FOR_COVER = 10 * 1024 * 1024;

export const USER_ACTIVITY_TYPE = {
  LOGIN: "Login",
};

export const GOALS_DETAILS_CONST = {
  0: { color: "orange", name: "Short", formControl: "six_months_goal" },
  1: { color: "blue-2", name: "Medium", formControl: "one_year_goal" },
  2: { color: "limegreen", name: "Long", formControl: "three_year_goal" },
};

export const TASK_STATUS_CONST = {
  OPENED: "open",
  CLOSED: "closed",
};

export const POST_PROFILE_CONSTS = {
  ADD_MANUALLY: "Add manually",
};

export const SORT_TYPE = {
  ASC: "asc",
  DESC: "desc",
};

export const JOB_STATUS_CONST = {
  LOOKING_FOR_JOB: "looking for a job",
  HIRING: "hiring",
  OPEN_TO_WORK: "open to work",
};
export const getTranslatedTabs = (t) => {
  return HOME_PAGE_TABS.map((tab) => ({
    ...tab,
    name: t(tab.name),
  }));
};

export const ACTIVITY_TAB_CONST = [
  {
    id: 1,
    name: "Posts",
    path: "posts",
  },
  {
    id: 2,
    name: "Comments",
    path: "comments",
  },
  {
    id: 3,
    name: "Images",
    path: "images",
  },
  {
    id: 4,
    name: "Documents",
    path: "documents",
  },
  {
    id: 5,
    name: "Reactions",
    path: "reactions",
  },
  {
    id: 6,
    name: "Saved",
    path: "saved",
  },
];

export const MAX_LENGTH_COMMENTS = 250;

export const CPD_STATUS_CONST = {
  COMPLETED : "completed",
  CANCELLED : "canceled",
  IN_PROGRESS : "in progress"
}

export const FILE_TYPE_CONST = {
  PDF : "pdf"
}
