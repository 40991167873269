import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './Slices/authSlice';
import themeSlice from './Slices/themeSlice';
import organisationSlice from './Slices/organisationSlice';
import userSlice from './Slices/userSlice';
import adminSlice from './Slices/adminSlice';


const rootReducer = combineReducers({
    auth: authSlice,
    theme: themeSlice,
    organisation: organisationSlice,
    user: userSlice,
    admin: adminSlice,
});

export default rootReducer;
